var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row align-center data-display-container" },
    [
      _c(
        "div",
        { staticClass: "data-display-icon" },
        [
          _c(
            "v-icon",
            { class: _vm.iconClass, attrs: { color: _vm.iconColor } },
            [_vm._v(_vm._s(_vm.icon))]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "data-display-content" }, [
        _c(
          "div",
          { staticClass: "data-display-title d-flex flex-row align-center" },
          [
            _vm._v(" " + _vm._s(_vm.title) + " "),
            _vm.hasHelper
              ? _c(
                  "div",
                  { staticClass: "data-display-helper" },
                  [
                    _c("IconTooltip", {
                      attrs: {
                        icon: "help",
                        iconClass: "material-symbols-outlined",
                        tooltipText: _vm.helpText,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c("div", { staticClass: "data-display-value" }, [
          _vm._v(" " + _vm._s(_vm.value) + " "),
        ]),
        _vm.hasExtraContainer
          ? _c(
              "div",
              { staticClass: "data-display-extra-container" },
              [_vm._t("default")],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }