<template>
    <div class="d-flex flex-row align-center data-display-container">
        <div class="data-display-icon">
            <v-icon :color="iconColor" :class="iconClass">{{ icon }}</v-icon>
        </div>
        <div class="data-display-content">
            <div class="data-display-title d-flex flex-row align-center">
                {{ title }}
                <div v-if="hasHelper" class="data-display-helper">
                    <IconTooltip
                        icon="help"
                        iconClass="material-symbols-outlined"
                        :tooltipText="helpText"
                    ></IconTooltip>
                </div>
            </div>
            <div class="data-display-value">
                {{ value }}
            </div>
            <div v-if="hasExtraContainer" class="data-display-extra-container">
                <slot>

                </slot>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "DataDisplay",
    components: {
        IconTooltip: () => import('./IconTooltip.vue')
    },
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        iconClass: {
            type: String,
            required: false
        },
        iconColor: {
            type: String,
            required: false
        },
        hasExtraContainer: {
            type: Boolean,
            default: false
        },
        hasHelper: {
            type: Boolean,
            default: false
        },
        helpText: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            
        }
    }
}

</script>

<style lang="scss" scoped>
@import './src/design/variables.scss';

.data-display-container {

    .data-display-icon {
        margin-right: $spacing-05;
        .v-icon {
            color: $neutral-color-low-dark;
        }
    }
    .data-display-content {
        width: 100%;
        .data-display-title {
            font-size: $font-size-xs;
            color: $neutral-color-high-medium;
            
            .data-display-helper {
                margin-left: $spacing-03;
                ::v-deep {
                    i {
                        font-size: $font-size-xs;
                    }
                }
            }
        }
        .data-display-value {
            color: $neutral-color-high-medium;
            font-size: $font-size-md;
        }
    }
}


</style>